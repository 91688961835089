import React, { Component } from "react";
import {ControlLabel, Grid, Row, Col } from "react-bootstrap";
import auth0Client from 'login/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import ReactTooltip from 'react-tooltip';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Calendar from "components/Calendar/Calendar.jsx";
import Select from 'react-select';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import { validator, sisperplus, fechasBusqueda} from 'store/Apis'
import 'react-confirm-alert/src/react-confirm-alert.css';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-bottom: 60px;
`;

class ConsultarBoletasAdm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      path: 'administrarBoletas',
      usuario: localStorage.getItem('nickname'),
      token: localStorage.getItem('AccessToken') != null ? 'Bearer ' + localStorage.getItem('AccessToken') : 0,
      id: JSON.parse(localStorage.getItem('datos'))[0].Person,
      movimientos: [],
      anio: '',
      mes: '',
      years: [],
      meses: [],
      isLoading: true
    }
    this.verdocumento= this.verdocumento.bind(this);
    this.verfirmado= this.verfirmado.bind(this);
 }
  handleAnio = anio => {
    this.setState(
    { anio },
    );
  };
  handleMes = mes => {
    this.setState(
    { mes },
    );
  };

  async componentDidMount() {
    if(localStorage.getItem('AccessToken') != null && localStorage.getItem('expires_at') != null && localStorage.getItem('operaciones') != null && localStorage.getItem('nickname') != null){
        axios.get(validator + this.state.path + '/'+ this.state.usuario, {
        headers: {
            Authorization: this.state.token
        }
        }).then((response) => {
            let permisos = response.data;
            if(permisos.codError != 1){
                this.props.history.goBack()
            }else{
                this.generarPeriodos();
            }
        },(error) => {
            if(auth0Client.isAuthenticated() === true){
            auth0Client.signOut()
            this.props.history.replace('/');
            }else{
            this.props.history.goBack()
            }
        });
    }else{
        auth0Client.signOut();
        this.props.history.replace('/');
    }
  }
 

  generarPeriodos(){
    let year =  2000;
    let cont = 26;
    let array = Array.from(new Array(cont),(val, index) => index + year);
    let arrayMes = [
      {"nid": "01", "descripcion": "ENERO"},
      {"nid": "02", "descripcion": "FEBRERO"},
      {"nid": "03", "descripcion": "MARZO"},
      {"nid": "04", "descripcion": "ABRIL"},
      {"nid": "05", "descripcion": "MAYO"},
      {"nid": "06", "descripcion": "JUNIO"},
      {"nid": "07", "descripcion": "JULIO"},
      {"nid": "08", "descripcion": "AGOSTO"},
      {"nid": "09", "descripcion": "SETIEMBRE"},
      {"nid": "10", "descripcion": "OCTUBRE"},
      {"nid": "11", "descripcion": "NOVIEMBRE"},
      {"nid": "12", "descripcion": "DICIEMBRE"},
    ]
    let cont2 = 0;
    let cont3 = 0;
    let lista = [];
    let lista2 = [];
    if(fechasBusqueda.boletas[0].nidperiodo != "" && fechasBusqueda.boletas[1].periodo != ""){
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
      })
      this.setState({anio: {
        value: fechasBusqueda.boletas[0].nidperiodo, 
        label: fechasBusqueda.boletas[1].periodo
      }})
    }else{
      array.map((data) => {
        cont2++
        lista.push({ 
            value: cont2,
            label: data,
        })
        if(data == (new Date()).getFullYear()){
          this.setState({anio: {value: cont2, label: data}})
        }
      })
    }
    if(fechasBusqueda.boletas[2].nidmes != "" && fechasBusqueda.boletas[3].mes != ""){
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
      })
      this.setState({mes: {
        value: fechasBusqueda.boletas[2].nidmes, 
        label: fechasBusqueda.boletas[3].mes, 
      }})
    }else{
      arrayMes.map((data) => {
        cont3++
        lista2.push({
            item: data.cont3,
            value: data.nid,
            label: data.descripcion,
        })
        if(cont3 == (new Date()).getMonth() + 1){
          this.setState({mes: {value: data.nid, label: data.descripcion}})
        }
      })
    }
    this.setState({years: lista, meses: lista2})
    this.listBoletas()

 }

  async listBoletas() {
    this.setState({isLoading: true})
    axios.defaults.headers.common['Authorization'] = this.state.token
    axios.get(`${sisperplus}` + 'boletaAdm/listBoletas?anio=' + this.state.anio.label + '&mes=' + this.state.mes.value)
    .then((res) => {
        let lista = [];
        let lista2 = res.data.response;
        if(lista2 != null){
          lista2.map((data) => {
            lista.push({ 
              nombres: data.nombres,
              dni: data.dni,
              archivo: data.archivo,
              item: data.item,
              nid: data.nid,
              anio:data.anio,
              mes:data.mes,
              estado: data.estado,    
              CTXT_ARCHIVOFIRMADO:data.archivofirmad,
              TXTFILE:data.TXTFILE,
              TXTFIRMADO:data.TXTFIRMADO,
              TXTMES: data.TXTMES
              

            

            })
          })
          fechasBusqueda.boletas[0].nidperiodo = this.state.anio.value
          fechasBusqueda.boletas[1].periodo = this.state.anio.label
          fechasBusqueda.boletas[2].nidmes = this.state.mes.value
          fechasBusqueda.boletas[3].mes = this.state.mes.label
          localStorage.setItem('fechasBusqueda', JSON.stringify(fechasBusqueda))
          this.setState({movimientos: lista, isLoading: false})
        }else{
          this.setState({movimientos: [], isLoading: false})
          this.props.handleClick("tl", "error", "Búsqueda incorrecta!", "pe-7s-info")
        }
    })
    .catch((error) => { 
      if(auth0Client.isAuthenticated() === true){
        auth0Client.signOut()
        this.props.history.replace('/');
      }else{
        console.log(error);
        
        this.setState({isLoading: false})
      }
    });
  }

  verdocumento(cell , row){
      if(row != undefined){
          return (
              <div>
              <span  data-tip="Ver Boleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row,"B") }}>
                  <ReactTooltip />
                <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 27, color:"#A10510"}}></i>
              </span>
              <span  data-tip="Descargar Boleta" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.descargar2(row.archivo) }}>
                  <ReactTooltip />
                <i className="fa fa-download fa-3x" style={{fontSize: 27, color:"#A10510"}}></i>
              </span>
              </div>
          )
      }
  }
 

  verfirmado(cell , row){
  
    if(row != undefined){
        return (      
          row.CTXT_ARCHIVOFIRMADO != null ?
            <div>
              <span  data-tip="Ver Boleta Firmada" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.ver(row,"F") }}>
                  <ReactTooltip />
                <i className="fa fa-file-pdf-o fa-3x" style={{fontSize: 27, color:"#142D8A"}}></i>
              </span>
              <span  data-tip="Descargar Boleta Firmada" className="fa-stack fa-2x" style={{cursor: 'pointer', fontSize: 20}} onClick={() => { this.descargar2(row.CTXT_ARCHIVOFIRMADO) }}>
                  <ReactTooltip />
                <i className="fa fa-download fa-3x" style={{fontSize: 27, color:"#142D8A"}}></i>
              </span>     
          </div>: null
        )
    }
  }

  async ver(data, param){
    if(param =="B"){
       window.open( data.TXTFILE, "_blank")
       // localStorage.setItem('visor', JSON.stringify(data))
    }else{
      window.open( data.TXTFIRMADO, "_blank")
      // let data2 = {
      //   "estado": "2",
      //   "CTXT_ARCHIVOFIRMADO": data.CTXT_ARCHIVOFIRMADO,
      //   "TXTFILE": data.TXTFILE,
      //   "TXTFIRMADO": data.TXTFIRMADO,
      //   "TXTMES":data.TXTMES
      //   ,
      // }
    
      //   localStorage.setItem('visor', JSON.stringify( data2))
    }
   // this.props.history.push('/visorboletas');
  }


  descargar2(archivo){
   

    window.open("https://gestordocumentaltc.sedetc.gob.pe/index.php/s/MSA22243tna4k7i/download?path=%2F&files=" + archivo, "_blank")
  }

  async verPensionistas(){
 
    this.props.history.push('/pensionistas');
  }
 

  render() {
    const columns = [
    {dataField: "item", text: "ITEM",  headerStyle: {backgroundColor: '#337AB7', color: "white",width:200}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "nombres", text: "NOMBRES Y APELLIDOS", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white",width:450}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "dni", text: "DNI", filter: textFilter(),  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', align: 'center'},
    {dataField: "archivo", text: "BOLETA",  headerStyle: {backgroundColor: '#337AB7', color: "white"}, headerAlign: (column, colIndex) => 'center', formatter : this.verdocumento, align:'center'},
    {dataField: "firmado", text: "BOLETA FIRMADA",  headerStyle: {backgroundColor: '#337AB7', color: "white" }, headerAlign: (column, colIndex) => 'center', formatter : this.verfirmado, align:'center'},

    ]
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'Primera',
      prePageText: 'Anterior',
      nextPageText: 'Siguiente',
      lastPageText: 'Última',
      showTotal: true,
      totalSize: this.state.movimientos.length
    };
    const contentTable = ({ paginationProps, paginationTableProps }) => (
      <div>
        <div>
          <div>
            <BootstrapTable
              striped
              hover
              keyField="item"
              data={ this.state.movimientos }
              columns={ columns }
              filter={ filterFactory() }
              noDataIndication="No existen registros para mostrar"
              { ...paginationTableProps }
            />
          </div>
          <div style={{float: "right"}}><PaginationListStandalone { ...paginationProps }/></div>
        </div>
      </div>
    );
    return (
      <div className="content">
        <Grid fluid>
        <Row>
            <Col md={12}>
              <div style={{borderRadius: 5, backgroundColor: "white", paddingLeft: 15, paddingRight: 15, paddingTop: 1, paddingBottom: 5}}>
              <h4 className="title" style={{fontWeight: 200, marginTop: 18, marginBottom: 25}}>Lista de Boletas</h4>
              <div className="row">


                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>PERIODO</ControlLabel>
                    <Select value={this.state.anio} onChange={this.handleAnio} options={this.state.years}/>
                </div>
                <div className="col-md-3" style={{marginBottom: 18}}>
                    <ControlLabel>MES</ControlLabel>
                    <Select value={this.state.mes} onChange={this.handleMes} options={this.state.meses}/>
                </div>

                <div className="col-md-6" style={{marginBottom: 18}}>
                    <ControlLabel></ControlLabel><br></br>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="success" fill type="submit" onClick={() => { this.listBoletas() }}>Buscar Boletas</Button>
                    <Button style={{width: 250, marginTop: 5, marginRight: 10, marginBottom: 10}} bsStyle="info" fill type="submit" onClick={() => { this.verPensionistas() }}>Ver Pensionistas</Button>
                </div>
              </div>
              {this.state.isLoading ? (
                      <ClipLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                    ) : (
                      <PaginationProvider
                        pagination={
                          paginationFactory(options)
                        }
                      >
                        { contentTable }
                      </PaginationProvider>
                    )}
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ConsultarBoletasAdm;