import Inicio from "views/inicio/Inicio.jsx";
import Marcaciones from "views/marcaciones/Marcaciones.jsx";
import Papeletas from "views/papeletas/Papeletas.jsx";
import verPapeleta from "views/papeletas/verPapeleta.jsx";
import newPapeleta from "views/papeletas/newPapeleta.jsx";
import Boletas from "views/boletas/Boletas.jsx";
import Salidas from "views/salidas/Salidas.jsx";
import VisorBoleta from "views/visor/VisorBoleta.jsx";
import VisorSalida from "views/visor/VisorSalida.jsx";
import VisorAutorizacion from "views/visor/VisorAutorizacion.jsx";
import VisorDetalle from "views/visor/VisorDetalle.jsx";
import Firma from "views/Firma.jsx";
import Autorizar from "views/autorizar/Autorizar.jsx";
import Administrador from "views/administrador/Administrador.jsx";
import Usuarios from "views/administrador/Usuarios.jsx";
import Asistencias from "views/administrador/Asistencias.jsx";
import Areas from "views/administrador/Areas.jsx";
import Marcas from "views/administrador/Marcas.jsx";
import Colaboradores from "views/administrador/Colaboradores.jsx";
import Trabajadores from "views/administrador/Trabajadores.jsx";
import Chat from "views/Chat.jsx";
import Perfil from "views/perfil/Perfil.jsx";
import Noticias from "views/Noticias.jsx";
import Mensajes from "views/Mensajes.jsx";
import Calendario from "views/Calendario.jsx";
import Configuracion from "views/configuracion/Configuracion.jsx";
import Cargar from "views/labores/Cargar.jsx";
import Aprobar from "views/labores/Aprobar.jsx";
import Remoto from "views/labores/Remoto.jsx";
import Emitir from "views/efectivo/Emitir.jsx";
import EditarSoli from "views/efectivo/EditarSoli.jsx";
import Bandeja from "views/efectivo/Bandeja.jsx";
import Concursos from "views/concursos/Concursos.jsx";
import Programas from "views/programas/Programas.jsx";
import Documentacion from "views/programas/Documentacion";
import DetPrograma from "views/programas/DetPrograma";
import PostulantesPas from "views/programas/Postulantes.jsx";
import FichaPostulantePas from "views/programas/FichaPostulante.jsx";
import DetConcurso from "views/concursos/DetConcurso.jsx";
import Entrevistas from "views/concursos/Entrevistas.jsx";
import Postulantes from "views/concursos/Postulantes.jsx";
import Detalle from "views/concursos/Detalle.jsx";
import FichaPostulante from "views/concursos/FichaPostulante.jsx";
import Canales from "views/canaltv/Canales.jsx";
import Videos from "views/canaltv/Videos.jsx";
import Horarios from "views/canaltv/Horarios.jsx";
import Normatividad     from "views/CEC/Publicaciones/Normatividad/Normatividad";
import CrearNormatividad from "views/CEC/Publicaciones/Normatividad/CrearNormatividad";
import EditarNormatividad from "views/CEC/Publicaciones/Normatividad/EditarNormatividad";
//Legajos - Trabajadores
// import AdministrarTrabajadores from 'views/Legajos/Trabajadores/Administrar.jsx'
// import ListarPensionistas from 'views/Legajos/Trabajadores/Listado.jsx'
// import AgregarTrabajador from 'views/Legajos/Trabajadores/Agregar.jsx'
// import EditarPersonal from 'views/Legajos/Trabajadores/Editar.jsx'

//Legajos - Personal
// import DetallePersonal from 'views/Legajos/Personal/Detalle.jsx'
// import ConsultarPersonal from 'views/Legajos/Personal/Listado.jsx'

//Legajos - Legajo - Familiares

// import AdministrarFamiliares from 'views/Legajos/Legajo/Familiares/Listado.jsx'
// import AgregarFamiliar from 'views/Legajos/Legajo/Familiares/Agregar.jsx'
// import EditarFamiliar from 'views/Legajos/Legajo/Familiares/Editar.jsx'

//Legajos - Legajo - Academico

// import HistoricoAcademico from 'views/Legajos/Legajo/Academico/Listado.jsx'
// import AgregarAcademico from 'views/Legajos/Legajo/Academico/Agregar.jsx'
// import EditarAcademico from 'views/Legajos/Legajo/Academico/Editar.jsx'

//Tesoreria - Beneficiarios


import ConsultaBeneficiarios from 'views/Tesoreria/Beneficiarios/ConsultaBeneficiarios.jsx'
import ConsultaConceptos from 'views/Tesoreria/Beneficiarios/ConsultaConceptos.jsx'

//Tesoreria - Utilitarios

import ConsultaUtilitarios from 'views/Tesoreria/Utilitarios/ConsultaUtilitarios.jsx'

//Tesoreria - Caja Chica

import ConsultaCajaChica from 'views/Tesoreria/CajaChica/ConsultaCajaChica.jsx'
import NuevaCajaChica from 'views/Tesoreria/CajaChica/NuevaCajaChica.jsx'
import EditarCajaChica from 'views/Tesoreria/CajaChica/EditarCajaChica.jsx'

//Tesoreria - Comprobantes

import ConsultaComprobantes from 'views/Tesoreria/Comprobantes/ConsultaComprobantes.jsx'
import NuevoComprobante from 'views/Tesoreria/Comprobantes/NuevoComprobante.jsx'
import EditarComprobante from 'views/Tesoreria/Comprobantes/EditarComprobante.jsx'
import ConsultaCheques from 'views/Tesoreria/Comprobantes/ConsultaCheques.jsx'
import ConsultaLibro from 'views/Tesoreria/Comprobantes/ConsultaLibro.jsx'
import EditarLibro from 'views/Tesoreria/Comprobantes/EditarLibro.jsx'
import GenerarCheque from 'views/Tesoreria/Comprobantes/GenerarCheque.jsx'
import GenerarReporte from 'views/Tesoreria/Comprobantes/GenerarReporte.jsx'
import ConsultaDocumentos from 'views/Tesoreria/Comprobantes/ConsultaDocumentos.jsx'

//Tesoreria - Reportes

import Reportes from 'views/Tesoreria/Reportes/Reportes.jsx'

//Auditoria

import AudiPostulaciones from "views/Auditoria/AudiPostulaciones.jsx";
import AudiPostulantes from "views/Auditoria/AudiPostulantes.jsx";
import AudiDetalle from "views/Auditoria/AudiDetalle.jsx";
import AudiFicha from "views/Auditoria/AudiFicha.jsx";

import AudiComprobantes from "views/Auditoria/AudiComprobantes.jsx";
import AudiCajaChica from "views/Auditoria/AudiCajaChica.jsx";

//CEC - Noticias
import ConsultarNoticias from 'views/CEC/Noticias/ConsultarNoticias.jsx'
import CrearNoticia from 'views/CEC/Noticias/CrearNoticia.jsx'
import DetalleNoticia from 'views/CEC/Noticias/DetalleNoticia.jsx'
import EditarNoticia from 'views/CEC/Noticias/EditarNoticia.jsx'

// CEC - Podcast
import ConsultarPodcasts from "views/CEC/Podcast/ConsultarPodcasts";
import CrearPodcasts from "views/CEC/Podcast/CrearPodcasts"
import EditarPodcasts from "views/CEC/Podcast/EditarPodcasts"

//CEC - Analytics
import ConsultarAnalytics from "views/CEC/Analytics/ConsultarAnalytics";  

//CEC - Biblioteca
import ConsultarBiblioteca from "views/CEC/Biblioteca/ConsultarBiblioteca";
import CrearBiblioteca from "views/CEC/Biblioteca/CrearBiblioteca"
import EditarBiblioteca from "views/CEC/Biblioteca/EditarBiblioteca"

//CEC - PUBLICACIONES
//import Publicaciones from "views/CEC/Publicaciones/Publicaciones.jsx";

import Publicaciones from "views/CEC/Publicaciones/PublicacionesCEC.jsx";

//SUBCARPETAS DE PUBLICACIONES

//CEC - PUBLICACIONES - LIBROS
import ConsultarLibros from "views/CEC/Publicaciones/Libros/ConsultarLibros.jsx";
import CrearLibros from "views/CEC/Publicaciones/Libros/CrearLibros.jsx";
import EditarLibros from "views/CEC/Publicaciones/Libros/EditarLibros";

//CEC - PUBLICACIONES - REVISTAS
import ConsultarRevistas from "views/CEC/Publicaciones/Revistas/ConsultarRevistas.jsx";
import CrearRevistas from "views/CEC/Publicaciones/Revistas/CrearRevistas.jsx";
import EditarRevistas from "views/CEC/Publicaciones/Revistas/EditarRevistas";

//CEC - PUBLICACIONES - CUADERNOS DE JURISPRUDENCIA
import ConsultarCuadernos from "views/CEC/Publicaciones/Cuadernos/ConsultarCuadernos.jsx";
import CrearCuadernos from "views/CEC/Publicaciones/Cuadernos/CrearCuadernos.jsx";
import EditarCuadernos from "views/CEC/Publicaciones/Cuadernos/EditarCuadernos";

//CEC - PUBLICACIONES - CONFERENCIAS
import ConsultarConferencia from "views/CEC/Publicaciones/FormacionyCapacitacion/Conferencia/ConsultarConferencia";
import CrearConferencia from "views/CEC/Publicaciones/FormacionyCapacitacion/Conferencia/CrearConferencia";
import EditarConferencia from "views/CEC/Publicaciones/FormacionyCapacitacion/Conferencia/EditarConferencia";

//CEC - PUBLICACIONES - CONVERSATORIO
import ConsultarConversatorio from "views/CEC/Publicaciones/Programas/Conversatorio/ConsultarConversatorio";
import CrearConversatorio from "views/CEC/Publicaciones/Programas/Conversatorio/CrearConversatorio";
import EditarConversatorio from "views/CEC/Publicaciones/Programas/Conversatorio/EditarConversatorio";

//CEC - PUBLICACIONES - DEBATES
import ConsultarDebate from "views/CEC/Publicaciones/Debate/ConsultarDebate";
import CrearDebate from "views/CEC/Publicaciones/Debate/CrearDebate";
import EditarDebate from "views/CEC/Publicaciones/Debate/EditarDebate";

//CEC - PUBLICACIONES - AGENDA CONSTITUCIONAL
import ConsultarAgendaConstitucional from "views/CEC/Publicaciones/FormacionyCapacitacion/AgendaConstitucional/ConsultarAgendaConstitucional";
import CrearAgendaConstitucional from "views/CEC/Publicaciones/FormacionyCapacitacion/AgendaConstitucional/CrearAgendaConstitucional";
import EditarAgendaConstitucional from "views/CEC/Publicaciones/FormacionyCapacitacion/AgendaConstitucional/EditarAgendaConstitucional";

//CEC - PUBLICACIONES - AGENDA CEC
import ConsultarAgendaCEC from "views/CEC/Publicaciones/AgendaCEC/ConsultarAgendaCEC";
import CrearAgendaCEC from "views/CEC/Publicaciones/AgendaCEC/CrearAgendaCEC";
import EditarAgendaCEC from "views/CEC/Publicaciones/AgendaCEC/EditarAgendaCEC";

//CEC - PUBLICACIONES - RIMAYKUNA CEC 
import ConsultarRimaykunaCEC from "views/CEC/Publicaciones/Rimaykuna/ConsultarRimaykunaCEC";
import CrearRimaykunaCEC from "views/CEC/Publicaciones/Rimaykuna/CrearRimaykunaCEC";
import EditarRimaykunaCEC from "views/CEC/Publicaciones/Rimaykuna/EditarRimaykunaCEC";

//CEC - FOROS
import Foros from "views/CEC/Foros/Foros.jsx";

//CEC -FORO ABIERTO
import ConsultarForoAbierto from "views/CEC/Foros/ForoAbierto/ConsultarForoAbierto";
import CrearForoAbierto from "views/CEC/Foros/ForoAbierto/CrearForoAbierto";
import EditarForoAbierto from "views/CEC/Foros/ForoAbierto/EditarForoAbierto";

//CEC -  FORO CONSTITUCIONAL
import ConsultarForoConstitucional from "views/CEC/Foros/ForoConstitucional/ConsultarForoConstitucional";
import CrearForoConstitucional from "views/CEC/Foros/ForoConstitucional/CrearForoConstitucional";
import EditarForoConstitucional from "views/CEC/Foros/ForoConstitucional/EditarForoConstitucional";

//CEC - FORO CONSTI-INCLUYENTE
import ConsultarForoConstiIncluyente from "views/CEC/Foros/ForoConstiIncluyente/ConsultarForoConstiIncluyente";
import CrearForoConstiIncluyente from "views/CEC/Foros/ForoConstiIncluyente/CrearForoConstiIncluyente";
import EditarForoConstiIncluyente from "views/CEC/Foros/ForoConstiIncluyente/EditarForoConstiIncluyente";

//CEC - UTILITARIOS
import Utilitarios from "views/CEC/Utilitarios/Utilitarios.jsx";

//CEC - UTILITARIOS - CARROUSEL
import ConsultarCarousel from "views/CEC/Utilitarios/ConsultarCarousel.jsx";
import CrearCarousel from "views/CEC/Utilitarios/CrearCarousel.jsx";
import EditarCarousel from "views/CEC/Utilitarios/EditarCarousel";
import VideoCEC from "views/CEC/Utilitarios/VideoCEC.jsx";

//CEC - CURSOS (CURSOS)
import ConsultarCursos from "views/CEC/Cursos/Cursos/ConsultarCursos"
import CrearCursos from "views/CEC/Cursos/Cursos/CrearCursos"
import EditarCursos from "views/CEC/Cursos/Cursos/EditarCursos"

//CEC - CURSOS  (VIDEOS DE CURSOS)
import ConsultarVideos from "views/CEC/Cursos/Videos/ConsultarVideos"
import CrearVideos from "views/CEC/Cursos/Videos/CrearVideos"
import EditarVideos from "views/CEC/Cursos/Videos/EditarVideos"

//Jurisdiccional - Noticias Salas
import ConsultarSalasNoticias from "views/Jurisdiccional/NoticiasSalas/ConsultarSalasNoticias"
import CrearSalasNoticias from "views/Jurisdiccional/NoticiasSalas/CrearSalasNoticias"
import EditarSalasNoticias from "views/Jurisdiccional/NoticiasSalas/EditarSalasNoticias"

//Jurisdiccional - Noticias Del CEC
import ConsultarCECNoticias from "views/Jurisdiccional/NoticiasCEC/ConsultarCECNoticias"
import CrearCECNoticias     from "views/Jurisdiccional/NoticiasCEC/CrearCECNoticias"
import EditarCECNoticias     from "views/Jurisdiccional/NoticiasCEC/EditarCECNoticias"

//Jurisdiccional - EXPEDIENTES VOTADOS - antes Agendas Pleno
import ConsultarExpedientesVotados from "views/Jurisdiccional/ExpedientesVotados/ConsultarExpedientesVotados"
import CrearExpedientesVotados    from "views/Jurisdiccional/ExpedientesVotados/CrearExpedientesVotados"
import EditarExpedientesVotados    from "views/Jurisdiccional/ExpedientesVotados/EditarExpedientesVotados"

//Jurisdiccional - Supervision de sentencia (CAUSA SEGUIMIENTO)
import ConsultarCausaSeguimiento from "views/Jurisdiccional/SupervisionSentencia/ConsultarCausaSeguimiento"

//Jurisdiccional - Supervision de sentencia (SEGUIMIENTO)
import ConsultarSeguimiento from "views/Jurisdiccional/SupervisionSentencia/ConsultarSeguimiento"
import CrearSeguimiento from "views/Jurisdiccional/SupervisionSentencia/CrearSeguimiento"
import EditarSeguimiento from "views/Jurisdiccional/SupervisionSentencia/EditarSeguimiento"

//Jurisdiccional - Ultimas Audiencias
import ConsultarUltimasAudiencias from "views/Jurisdiccional/UltimasAudiencias/ConsultarUltimasAudiencias"
import CrearUltimasAudiencias     from "views/Jurisdiccional/UltimasAudiencias/CrearUltimasAudiencias"
import EditarUltimasAudiencias     from "views/Jurisdiccional/UltimasAudiencias/EditarUltimasAudiencias"

//Jurisdiccional - Votaciones
import Votaciones from "views/Jurisdiccional/Votaciones/Votaciones"
import Evento from "views/Jurisdiccional/Votaciones/Evento"
import Personal from "views/Jurisdiccional/Votaciones/Personal"
import Opciones from "views/Jurisdiccional/Votaciones/Opciones"
import Miembros from "views/Jurisdiccional/Votaciones/Miembros"
import Votantes from "views/Jurisdiccional/Votaciones/Votantes"

//Jurisdiccional - Penalidades
import ConsultarPenalidades from "views/Jurisdiccional/Penalidades/ConsultarPenalidades"
import CrearPenalidades from "views/Jurisdiccional/Penalidades/CrearPenalidades"
import EditarPenalidades from "views/Jurisdiccional/Penalidades/EditarPenalidades"

//Jurisdiccional - CarouselPortal
import CarruselPortal from "views/Jurisdiccional/CarruselPortalWeb/CarruselPortal"
import ConsultarCarruselPortal from "views/Jurisdiccional/CarruselPortalWeb/ConsultarCarruselPortal"
import CrearCarruselPortal from "views/Jurisdiccional/CarruselPortalWeb/CrearCarruselPortal"
import EditarCarruselPortal from "views/Jurisdiccional/CarruselPortalWeb/EditarCarruselPortal"

//RRHH - BOLETAS
import ConsultarBoletasAdm from "views/RRHH/Boletas/ConsultarBoletasAdm"

//RRHH -PAPELETAS
import ConsultarPapeletasAdm from "views/RRHH/Papeletas/ConsultarPapeletasAdm"
import CrearPapeletaAdm from "views/RRHH/Papeletas/CrearPapeletaAdm"
import VerPapeletaAdm from "views/RRHH/Papeletas/VerPapeletaAdm"

// RRHH - TRABAJADORES
import ConsultarTrabajadores from "views/RRHH/Trabajadores/ConsultarTrabajadores";
import CrearTrabajadores from "views/RRHH/Trabajadores/CrearTrabajadores";
import EditarTrabajadores from "views/RRHH/Trabajadores/EditarTrabajadores";
import VerPapeletaTrabajador from "views/RRHH/Trabajadores/VerPapeletaTrabajador";

//Tesoreria - Penalidades
import Penalidades from "views/Tesoreria/Penalidades/Penalidades"

//RRHH - Administration (AREAS Y PERMISOS)
import General from "views/RRHH/Administration/General"

//RRHH - Administration (Cargos)
import AFP from "views/RRHH/Administration/Utilitarios/AFP"
import Bancos from "views/RRHH/Administration/Utilitarios/Bancos"
import Pension from "views/RRHH/Administration/Utilitarios/Pension"

import Cargos from "views/RRHH/Administration/Cargos"
import Regimen from "views/RRHH/Administration/Regimen"
import UtilitariosAdm from "views/RRHH/Administration/UtilitariosAdm"
import TipoPapeleta from "views/RRHH/Administration/TipoPapeleta"

import OpcionJefatura from "views/RRHH/Administration/OpcionJefatura"

// Jurisprudencia - Tesaurios
import ConsultarTesauros from "views/Jurisprudencia/Tesauro/ConsultarTesauros";

//RRHH -AREAS ADMIN
import AreasAdm from "views/RRHH/Administration/Area/AreasAdm"
import CrearArea from "views/RRHH/Administration/Area/CrearArea"
import EditarArea from "views/RRHH/Administration/Area/EditarArea"

//Permisos
import Permisos from "views/RRHH/Administration/Area/Permisos"
//Subareas
import Subareas from "views/RRHH/Administration/Area/Subareas"

//TrabajadoresArea
import TrabajadoresAreas from "views/RRHH/Administration/Area/TrabajadoresAreas"

//Conceptos
import ConceptosPago from "views/RRHH/Administration/ConceptosPago"

//Tipo de Descanso Médico
import TipoDescansoMedico from "views/RRHH/Administration/TipoDescansoMedico"

//PERSONAL - DIRECTORIOS
import Directorios from "views/Personal/Directorios/Directorios"

//PERSONAL - MANUALES
import ListManuales from "views/Personal/Manuales/ListManuales"

//JURISDICCIONAL - MODELO INTEGRIDAD
import ModeloIntegridad from "views/Jurisdiccional/ModeloIntegridad/ModeloIntegridad"

//Boletín
import Boletines from "views/Jurisdiccional/BoletinesTc/Boletines"
import CrearBoletin from "views/Jurisdiccional/BoletinesTc/CrearBoletin"
import EditarBoletin from "views/Jurisdiccional/BoletinesTc/EditarBoletin"
import ListarEnviado from "views/Jurisdiccional/BoletinesTc/ListarEnviado"

//Aula Virtual
import Inscripciones from "views/CEC/AulaVirtual/Inscripciones"
import Plantillas from "views/CEC/AulaVirtual/Plantillas"
import Certificados from "views/CEC/AulaVirtual/Certificados"

//DOCUMENTARIO
import MenuDocumentario from "views/Documentario/MenuDocumentario";
import ContadorTramite from "views/Documentario/ContadorTramite";
import ListadoBandejas from "views/Documentario/ListadoBandejas";
import SecuenciaxArea from "views/Documentario/SecuenciaxArea";
import SecuenciaxPeriodo from "views/Documentario/SecuenciaxPeriodo";

//JEFATURA
import ReporteTramites from "views/Jefatura/ReporteTramites";

//BOLETA PENSIONISTAS
import Pensionistas from "views/RRHH/Boletas/Pensionistas";

//CERTIFICADOS
import DirectorioCertificado from "views/CEC/AulaVirtual/DirectorioCertificado";

//DM
import DescansoMedico from "views/RRHH/DescansoMedico/DescansoMedico";
import DiasDescansoMedico from "views/RRHH/Administration/DiasDescansoMedico"
import NuevoDescansoMedico from "views/RRHH/DescansoMedico/NuevoDescansoMedico";
import VerDescansoTrabajador from "views/RRHH/DescansoMedico/VerDescansoTrabajador";
import EditarDescansoMedico from "views/RRHH/DescansoMedico/EditarDescansoMedico";
import GestionarDM from "views/RRHH/DescansoMedico/GestionarDM";
import ReporteDias from "views/RRHH/DescansoMedico/ReporteDias";

//CERTIFICADOS DOCUMENTOS
import CertiFirmados from "views/CEC/AulaVirtual/CertiFirmados";

//TIPOS DE CESE
import TipoCese from "views/RRHH/Administration/TipoCese";

//VACACIONES
import Vacaciones from "views/RRHH/Vacaciones/Vacaciones";
import DetalleVacaciones from "views/RRHH/Vacaciones/DetalleVacaciones"
import VerVacacionesTrabajador from "views/RRHH/Vacaciones/VerVacacionesTrabajador"
import ReporteVacaciones from "views/RRHH/Vacaciones/ReporteVacaciones"
import AllVacacionesTrabajador from "views/RRHH/Vacaciones/AllVacacionesTrabajador"

//FIRMA
import GenerarFirma from "views/Personal/Firma/GenerarFirma"

//CEC - PUBLICACIONES - COLOQUIOS 
import ConsultarColoquios from "views/CEC/Publicaciones/Programas/Coloquios/ConsultarColoquios";
import CrearColoquios from "views/CEC/Publicaciones/Programas/Coloquios/CrearColoquios";
import EditarColoquios from "views/CEC/Publicaciones/Programas/Coloquios/EditarColoquios";

import ConsultarReplica from "views/CEC/Publicaciones/Programas/ReplicaConstitucional/ConsultarReplica";
import CrearReplica from "views/CEC/Publicaciones/Programas/ReplicaConstitucional/CrearReplica";
import EditarReplica from "views/CEC/Publicaciones/Programas/ReplicaConstitucional/EditarReplica";
import SubCategoriaCuaderno from "views/CEC/Publicaciones/FondoEditorial/SubCategoriaCuaderno";

//Lista tramite
import ListaTramite from "views/Documentario/ListadoTramite";

//TRABAJADORES PARA ASIGNAR AREA
import AreaTrabajadores from "views/RRHH/AreaTrabajadores/AreaTrabajadores";

//Resoluciones
import Resoluciones from "views/Administrativo/Resoluciones/Resoluciones";
import NuevaResolucion from "views/Administrativo/Resoluciones/NuevaResolucion";
import EditarResolucion from "views/Administrativo/Resoluciones/EditarResolucion";
import Notificar from "views/Administrativo/Resoluciones/Notificar";
import Categorias from "views/Administrativo/Resoluciones/Categorias";
import MisResoluciones from "views/Administrativo/Resoluciones/MisResoluciones";
import VerNotificados from "views/Administrativo/Resoluciones/VerNotificados";
import VisorResolucion from "views/visor/VisorResolucion.jsx";

//Produccion
import Produccion from "views/produccion/Produccion.jsx";
import VerReporte from "views/produccion/VerReporte";


//Espacio Internacional
import EspacioInternacional from "views/CEC/Publicaciones/EspacioInternacional/EspacioInternacional.jsx";
import AdminEspacio from "views/CEC/Publicaciones/EspacioInternacional/AdminEspacio.jsx";
//JEFATURA
import AsignacionJefes from "views/Jefatura/AsignacionJefes";

import Secciones from "views/CEC/Publicaciones/SeccionesConHTML/Secciones.jsx";
import AdminSecciones from "views/CEC/Publicaciones/SeccionesConHTML/AdminSecciones";

import ProgramasCEC from "views/CEC/Publicaciones/Programas/ProgramasCEC";
import Equipo from "views/CEC/Publicaciones/Equipo/Equipo.jsx";

import FormacionCap from "views/CEC/Publicaciones/FormacionyCapacitacion/FormacionCap";

//CEC - DIALOGO CON MAGISTRADOS
import ConsultarDialogo from "views/CEC/Publicaciones/FormacionyCapacitacion/Dialogo/ConsultarDialogo";
import CrearDialogo from "views/CEC/Publicaciones/FormacionyCapacitacion/Dialogo/CrearDialogo";
import EditarDialogo from "views/CEC/Publicaciones/FormacionyCapacitacion/Dialogo/EditarDialogo";

import Memorias from "views/CEC/Publicaciones/Memorias/Memorias.jsx";
import MemoriaAdmin from "views/CEC/Publicaciones/Memorias/MemoriaAdmin/MemoriaAdmin.jsx";
import CrearMemoria from "views/CEC/Publicaciones/Memorias/MemoriaAdmin/CrearMemoria.jsx";
import EditarMemoria from "views/CEC/Publicaciones/Memorias/MemoriaAdmin/EditarMemoria.jsx";

import Listadelineas from "views/CEC/Publicaciones/LineasInvestigacion/Listadelineas";
import ListaSecciones from "views/CEC/Publicaciones/LineasInvestigacion/ListaSecciones";
import ListaSubSecciones from "views/CEC/Publicaciones/LineasInvestigacion/ListaSubSecciones";
import AdminLineas from "views/CEC/Publicaciones/LineasInvestigacion/AdminLineas";

import ActCapacitacion from "views/CEC/Publicaciones/FormacionyCapacitacion/ActCapacitacion/ActCapacitacion";
import CentroDocumentacion from "views/CEC/Publicaciones/CentroDocumentacion/CentroDocumentacion";
import Ferias from "views/CEC/Publicaciones/CentroDocumentacion/Ferias/Ferias";
import CrearFeria from "views/CEC/Publicaciones/CentroDocumentacion/Ferias/CrearFeria";
import EditarFeria from "views/CEC/Publicaciones/CentroDocumentacion/Ferias/EditarFeria";
import DetalleFeria from "views/CEC/Publicaciones/CentroDocumentacion/Ferias/DetalleFeria";

import VerConcursos from "views/concursos/VerConcursos";

import AdminMarcaciones from "views/RRHH/Marcaciones/AdminMarcaciones";

import ListSubCategorias from "views/CEC/Publicaciones/FormacionyCapacitacion/ActCapacitacion/ListSubCategorias";
import ListSubSecciones from "views/CEC/Publicaciones/FormacionyCapacitacion/ActCapacitacion/ListSubSecciones";

//Legajo
import ListadoTrabajadores from "views/RRHH/Legajos/ListadoTrabajadores";
import ConsultarMiLegajo from "views/MiLegajo/ConsultarMiLegajo";
import ConsultarLegajoTrabajador from "views/RRHH/Legajos/ConsultarLegajoTrabajador";
import SeccionesLegajo from "views/RRHH/Administration/SeccionesLegajo";
import FichaPersonal from "views/MiLegajo/Personal/FichaPersonal";
import DatosGenerales from "views/MiLegajo/Personal/DatosGenerales";
import ListadoFormacion from "views/MiLegajo/Academico/ListadoFormacion";
import ListadoFamiliar from "views/MiLegajo/Familiares/ListadoFamiliar";
import AgregarFamiliar from "views/MiLegajo/Familiares/AgregarFamiliar";
import AgregarFormacion from "views/MiLegajo/Academico/AgregarFormacion";
import ListadoExperiencia from "views/MiLegajo/Laboral/ListadoExperiencia";
import AgregarExperiencia from "views/MiLegajo/Laboral/AgregarExperiencia";
import DeclaracionJurada from "views/MiLegajo/Personal/DeclaracionJurada";
import EditarFamiliar from "views/MiLegajo/Familiares/EditarFamiliar";
import EditarFormacion from "views/MiLegajo/Academico/EditarFormacion";
import EditarExperiencia from "views/MiLegajo/Laboral/EditarExperiencia";
import GenerarCaratula from "views/RRHH/Legajos/GenerarCaratula";

//DM
import ReporteDM from "views/RRHH/DescansoMedico/ReporteDM";
import ListadoDescansoMedico from "views/Personal/DescansoMedico/ListadoDescansoMedico";
import AgregarDescansoMedico from "views/Personal/DescansoMedico/AgregarDescansoMedico";
import ModificarDescansoMedico from "views/Personal/DescansoMedico/ModificarDescansoMedico";
import AutorizarDescansoM from "views/Jefatura/AutorizarDescansoM";

import ListadoVacaciones from "views/Personal/Vacaciones/ListadoVacaciones";
import ReporteGeneralV from "views/RRHH/Vacaciones/ReporteGeneralV";
import ConsultarVacaciones from "views/Personal/Vacaciones/ConsultarVacaciones";
import ReporteControl from "views/RRHH/DescansoMedico/ReporteControl";
import ReporteRegistro from "views/RRHH/DescansoMedico/ReporteRegistro";
import ReporteAcumulado from "views/RRHH/DescansoMedico/ReporteAcumulado";

//rrhh - Desplazamiento
import Desplazamiento from "views/RRHH/Desplazamiento/desplazamiento";
import NuevoDesplazamiento from "views/RRHH/Desplazamiento/nuevoDesplazamiento";
import DetalleDesplazamiento from "views/RRHH/Desplazamiento/desplazamientoDetalle";

import DetallePostulanteInterno from "views/concursos/DetallePostulanteInterno";

import FondoEditorial   from "views/CEC/Publicaciones/FondoEditorial/FondoEditorial";

import ConsultarEditorial     from "views/CEC/Publicaciones/FondoEditorial/ConsultarEditorial";
import CrearEditorial from "views/CEC/Publicaciones/FondoEditorial/CrearEditorial";
import EditarEditorial  from "views/CEC/Publicaciones/FondoEditorial/EditarEditorial";

import Pinacoteca from "views/Jurisdiccional/Pinacoteca/PinacotecaCrear";

//rrhh - Consolidado
import Consolidado from "views/RRHH/Consolidado/Consolidado";

const dashboardRoutes = [
  {
    path: "/inicio",
    name: "INICIO",
    icon: "pe-7s-home",
    component: Inicio,
    layout: "/admin"
  },
  {
    path: "/marcaciones",
    name: "MARCACIONES",
    icon: "pe-7s-display2",
    component: Marcaciones,
    layout: "/admin"
  },
  {
    path: "/papeletas",
    name: "PAPELETAS",
    icon: "pe-7s-note2",
    component: Papeletas,
    layout: "/admin"
  },
  {
    path: "/visorautorizacion",
    name: "VISOR DE DOCUMENTOS",
    icon: "",
    component: VisorAutorizacion,
    layout: "/admin"
  },
  {
    path: "/nuevapapeleta",
    name: "NUEVA PAPELETA",
    icon: "",
    component: newPapeleta,
    layout: "/admin"
  },
  {
    path: "/detalle",
    name: "DETALLE DE PAPELETA",
    icon: "",
    component: verPapeleta,
    layout: "/admin"
  },
  {
    path: "/visordetalle",
    name: "VISOR DE ADJUNTOS",
    icon: "",
    component: VisorDetalle,
    layout: "/admin"
  },
  {
    path: "/boletas",
    name: "BOLETAS DE PAGO",
    icon: "pe-7s-mail-open-file",
    component: Boletas,
    layout: "/admin"
  },
  {
    path: "/salidas",
    name: "OTRAS SALIDAS",
    icon: "pe-7s-copy-file",
    component: Salidas,
    layout: "/admin"
  },
  {
    path: "/visorboletas",
    name: "VISOR DE BOLETAS",
    icon: "",
    component: VisorBoleta,
    layout: "/admin"
  },
  {
    path: "/visorsalidas",
    name: "VISOR DE SALIDAS",
    icon: "",
    component: VisorSalida,
    layout: "/admin"
  },
  {
    path: "/firma",
    name: "FIRMAR BOLETAS",
    icon: "pe-7s-pen",
    component: Firma,
    layout: "/admin"
  },
  {
    path: "/autorizar",
    name: "AUTORIZAR PAPELETAS",
    icon: "pe-7s-check",
    component: Autorizar,
    layout: "/admin"
  },
  {
    path: "/administrador",
    name: "ADMINISTRADOR",
    icon: "pe-7s-config",
    component: Administrador,
    layout: "/admin"
  },
  {
    path: "/usuarios",
    name: "USUARIOS",
    icon: "",
    component: Usuarios,
    layout: "/admin"
  },
  {
    path: "/asistencias",
    name: "ASISTENCIA",
    icon: "",
    component: Asistencias,
    layout: "/admin"
  },
  {
    path: "/areas",
    name: "AREAS",
    icon: "",
    component: Areas,
    layout: "/admin"
  },
  {
    path: "/marcas",
    name: "MARCACIONES POR DÍA",
    icon: "",
    component: Marcas,
    layout: "/admin"
  },
  {
    path: "/colaboradores",
    name: "COLABORADORES",
    icon: "",
    component: Colaboradores,
    layout: "/admin"
  },
  {
    path: "/trabajadores",
    name: "TRABAJADORES",
    icon: "",
    component: Trabajadores,
    layout: "/admin"
  },
  {
    path: "/miTeletrabajo",
    name: "MI TELETRABAJO",
    icon: "",
    component: Cargar,
    layout: "/admin"
  },
  {
    path: "/aprobar",
    name: "APROBAR TELETRABAJOS",
    icon: "",
    component: Aprobar,
    layout: "/admin"
  },
  {
    path: "/emitir",
    name: "SOLICITAR EFECTIVO",
    icon: "",
    component: Emitir,
    layout: "/admin"
  },
  {
    path: "/editarSoli",
    name: "EDITAR SOLICITUD",
    icon: "",
    component: EditarSoli,
    layout: "/admin"
  },
  {
    path: "/bandeja",
    name: "BANDEJA DE PAGOS EN EFECTIVO",
    icon: "",
    component: Bandeja,
    layout: "/admin"
  },
  {
    path: "/programas",
    name: "PROGRAMAS DE EXTENSIÓN PUBLICADAS",
    icon: "",
    component: Programas,
    layout: "/admin"
  },
  {
    path: "/documentacion",
    name: "DOCUMENTACIÓN DEL PROGRAMA DE EXTENSIÓN",
    icon: "",
    component: Documentacion,
    layout: "/admin"
  },
  {
    path: "/detPrograma",
    name: "DETALLE DEL PROGRAMA DE EXTENSIÓN",
    icon: "",
    component: DetPrograma,
    layout: "/admin"
  },
  {
    path: "/postulantesPas",
    name: "POSTULANTES DEL PROGRAMA DE EXTENSIÓN",
    icon: "",
    component: PostulantesPas,
    layout: "/admin"
  },
  {
    path: "/fichaPostulantePas",
    name: "FICHA DEL POSTULANTE",
    icon: "",
    component: FichaPostulantePas,
    layout: "/admin"
  },
  {
    path: "/concursos",
    name: "CONCURSOS PUBLICADOS",
    icon: "",
    component: Concursos,
    layout: "/admin"
  },
  {
    path: "/detConcurso",
    name: "DETALLE DEL CONCURSO",
    icon: "",
    component: DetConcurso,
    layout: "/admin"
  },
  {
    path: "/entrevistas",
    name: "ENTREVISTAS DEL CONCURSO",
    icon: "",
    component: Entrevistas,
    layout: "/admin"
  },
  {
    path: "/postulantes",
    name: "POSTULANTES DEL CONCURSO",
    icon: "",
    component: Postulantes,
    layout: "/admin"
  },
  {
    path: "/detallePostulante",
    name: "DETALLE DEL POSTULANTE",
    icon: "",
    component: Detalle,
    layout: "/admin"
  },
  {
    path: "/fichaPostulante",
    name: "FICHA DEL POSTULANTE",
    icon: "",
    component: FichaPostulante,
    layout: "/admin"
  },
  {
    path: "/canales",
    name: "CANALES TV",
    icon: "",
    component: Canales,
    layout: "/admin"
  },
  {
    path: "/videos",
    name: "VIDEOS PUBLICADOS",
    icon: "",
    component: Videos,
    layout: "/admin"
  },
  {
    path: "/horarios",
    name: "HORARIOS DEL CANAL",
    icon: "",
    component: Horarios,
    layout: "/admin"
  },
  {
    path: "/teletrabajo",
    name: "TELETRABAJO",
    icon: "",
    component: Remoto,
    layout: "/admin"
  },
  {
    path: "/chat",
    name: "CHAT TRIBUNAL",
    icon: "pe-7s-chat",
    component: Chat,
    layout: "/admin"
  },
  {
    path: "/perfil",
    name: "PERFIL",
    icon: "",
    component: Perfil,
    layout: "/admin"
  },
  {
    path: "/noticiasPersonal",
    name: "NOTICIAS",
    icon: "",
    component: Noticias,
    layout: "/admin"
  },
  {
    path: "/mensajes",
    name: "MENSAJES",
    icon: "",
    component: Mensajes,
    layout: "/admin"
  },
  {
    path: "/calendario",
    name: "CALENDARIO",
    icon: "",
    component:  Calendario,
    layout: "/admin"
  },
  {
    path: "/configuracion",
    name: "CONFIGURACIÓN",
    icon: "",
    component: Configuracion,
    layout: "/admin"
  },
  //Nuevas Rutas
  // {
  //   path: "/administrarTrabajadores",
  //   name: "ADMINISTRAR TRABAJADORES",
  //   icon: "",
  //   component: AdministrarTrabajadores,
  //   layout: "/admin"
  // },
  // {
  //   path: "/administrarPensionistas",
  //   name: "ADMINISTRAR PENSIONISTAS",
  //   icon: "",
  //   component: ListarPensionistas,
  //   layout: "/admin"
  // },
  // {
  //   path: "/agregarTrabajador",
  //   name: "AGREGAR TRABAJADOR",
  //   icon: "",
  //   component: AgregarTrabajador,
  //   layout: "/admin"
  // },
  // {
  //   path: "/editarPersonal",
  //   name: "EDITAR PERSONAL",
  //   icon: "",
  //   component: EditarPersonal,
  //   layout: "/admin"
  // },
  // {
  //   path: "/consultarPersonal",
  //   name: "CONSULTAR PERSONAL",
  //   icon: "",
  //   component: ConsultarPersonal,
  //   layout: "/admin"
  // },
  // {
  //   path: "/detallePersonal",
  //   name: "DETALLE DEL PERSONAL",
  //   icon: "",
  //   component: DetallePersonal,
  //   layout: "/admin"
  // },
  // {
  //   path: "/administrarFamiliares",
  //   name: "ADMINISTRAR FAMILIARES",
  //   icon: "",
  //   component: AdministrarFamiliares,
  //   layout: "/admin"
  // },
  // {
  //   path: "/agregarFamilia",
  //   name: "AGREGAR FAMILIAR",
  //   icon: "",
  //   component: AgregarFamiliar,
  //   layout: "/admin"
  // },
  // {
  //   path: "/editarFamiliar",
  //   name: "EDITAR FAMILIAR",
  //   icon: "",
  //   component: EditarFamiliar,
  //   layout: "/admin"
  // },
  // {
  //   path: "/historicoAcademico",
  //   name: "HISTÓRICO ACADÉMICO",
  //   icon: "",
  //   component: HistoricoAcademico,
  //   layout: "/admin"
  // },
  // {
  //   path: "/agregarAcademico",
  //   name: "AGREGAR DATOS ACADÉMICOS",
  //   icon: "",
  //   component: AgregarAcademico,
  //   layout: "/admin"
  // },
  // {
  //   path: "/editarAcademico",
  //   name: "EDITAR DATOS ACADÉMICOS",
  //   icon: "",
  //   component: EditarAcademico,
  //   layout: "/admin"
  // },
  //Tesoreria - Beneficiarios
  {
    path: "/beneficiarios",
    name: "CONSULTA DE BENEFICIARIOS",
    icon: "",
    component: ConsultaBeneficiarios,
    layout: "/admin"
  },
  {
    path: "/conceptos",
    name: "CONSULTAR CONCEPTOS",
    icon: "",
    component: ConsultaConceptos,
    layout: "/admin"
  },
  //Tesoreria - Utilitarios
  {
    path: "/tablasmaestras",
    name: "TABLAS MAESTRAS",
    icon: "",
    component: ConsultaUtilitarios,
    layout: "/admin"
  },
  //Tesoreria - Caja Chica
  {
    path: "/cajachica",
    name: "CONSULTAR CAJA CHICA",
    icon: "",
    component: ConsultaCajaChica,
    layout: "/admin"
  },
  {
    path: "/nuevacajachica",
    name: "NUEVA CAJA CHICA",
    icon: "",
    component: NuevaCajaChica,
    layout: "/admin"
  },
  {
    path: "/editarcajachica",
    name: "EDITAR CAJA CHICA",
    icon: "",
    component: EditarCajaChica,
    layout: "/admin"
  },
  //Tesoreria - Comprobantes
  {
    path: "/comprobantes",
    name: "CONSULTAR COMPROBANTES",
    icon: "",
    component: ConsultaComprobantes,
    layout: "/admin"
  },
  {
    path: "/nuevocomprobante",
    name: "NUEVO COMPROBANTE",
    icon: "",
    component: NuevoComprobante,
    layout: "/admin"
  },
  {
    path: "/editarcomprobante",
    name: "EDITAR COMPROBANTE",
    icon: "",
    component: EditarComprobante,
    layout: "/admin"
  },
  {
    path: "/cheques",
    name: "CONSULTAR CHEQUES",
    icon: "",
    component: ConsultaCheques,
    layout: "/admin"
  },
  {
    path: "/libro",
    name: "CONSULTAR LIBRO",
    icon: "",
    component: ConsultaLibro,
    layout: "/admin"
  },
  {
    path: "/editarlibro",
    name: "EDITAR LIBRO",
    icon: "",
    component: EditarLibro,
    layout: "/admin"
  },
  {
    path: "/generarcheque",
    name: "GENERAR CHEQUE",
    icon: "",
    component: GenerarCheque,
    layout: "/admin"
  },
  {
    path: "/generarreporte",
    name: "GENERAR REPORTE",
    icon: "",
    component: GenerarReporte,
    layout: "/admin"
  },
  {
    path: "/consultadocumentos",
    name: "DOCUMENTOS DEL COMPROBANTE",
    icon: "",
    component: ConsultaDocumentos,
    layout: "/admin"
  },
  //Tesoreria - Reportes
  {
    path: "/reportes",
    name: "REPORTES DE TESORERÍA",
    icon: "",
    component: Reportes,
    layout: "/admin"
  },
  //Auditoria
  {
    path: "/audiPostulaciones",
    name: "CONCURSOS PUBLICADOS",
    icon: "",
    component: AudiPostulaciones,
    layout: "/admin"
  },
  {
    path: "/audiPostulantes",
    name: "POSTULANTES DEL CONCURSO",
    icon: "",
    component: AudiPostulantes,
    layout: "/admin"
  },
  {
    path: "/audiDetalle",
    name: "DETALLE DEL POSTULANTE",
    icon: "",
    component: AudiDetalle,
    layout: "/admin"
  },
  {
    path: "/audiFicha",
    name: "FICHA DEL POSTULANTE",
    icon: "",
    component: AudiFicha,
    layout: "/admin"
  },
  {
    path: "/audiComprobantes",
    name: "LISTA DE COMPROBANTES",
    icon: "",
    component: AudiComprobantes,
    layout: "/admin"
  },
  {
    path: "/audiCajaChica",
    name: "LISTA DE CAJA CHICA",
    icon: "",
    component: AudiCajaChica,
    layout: "/admin"
  },
  //CEC -Noticia
  {
    path: "/noticias",
    name: "NOTICIAS",
    icon: "",
    component: ConsultarNoticias,
    layout: "/admin"
  },
  {
    path: "/crearNoticia",
    name: "NUEVA PUBLICACIÓN",
    icon: "",
    component: CrearNoticia,
    layout: "/admin"
  },
  {
    path: "/noticiadetalle",
    name: "DETALLE DE PUBLICACIÓN",
    icon: "",
    component: DetalleNoticia,
    layout: "/admin"
  },
  {
    path: "/editarNoticia",
    name: "EDITAR PUBLICACIÓN",
    icon: "",
    component:EditarNoticia,
    layout: "/admin"
  },
  //CEC - Podcast
  {
    path: "/podcasts",
    name: "CONSULTAR PODCASTS",
    icon: "",
    component: ConsultarPodcasts,
    layout: "/admin"
  },
  {
    path: "/crearpodcasts",
    name: "CREAR PODCASTS",
    icon: "",
    component: CrearPodcasts,
    layout: "/admin"
  },
  {
    path: "/editarPodcasts",
    name: "EDITAR PODCAST",
    icon: "",
    component:EditarPodcasts,
    layout: "/admin"
  },
  //CEC - Analytics
  {
    path: "/analytics",
    name: "CONSULTAR ANALYTICS",
    icon: "",
    component: ConsultarAnalytics,
    layout: "/admin"
  },
  //CEC - Biblioteca
  {
    path: "/biblioteca",
    name: "CONSULTAR BIBLIOTECA",
    icon: "",
    component: ConsultarBiblioteca,
    layout: "/admin"
  },
  {
    path: "/crearbiblioteca",
    name: "CREAR LIBRO",
    icon: "",
    component: CrearBiblioteca,
    layout: "/admin"
  },
  {
    path: "/editarbiblioteca",
    name: "EDITAR LIBRO",
    icon: "",
    component: EditarBiblioteca,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES
  {
    path: "/publicaciones",
    name: "PUBLICACIONES",
    icon: "",
    component: Publicaciones,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - CAROUSEL
  {
    path: "/consultarcarousel",
    name: "CAROUSEL DEL CEC",
    icon: "",
    component: ConsultarCarousel,
    layout: "/admin"
  },
  {
    path: "/videoCEC",
    name: "VIDEO SOBRE DEL CEC",
    icon: "",
    component: VideoCEC,
    layout: "/admin"
  },
  {
    path: "/crearcarousel",
    name: "NUEVO CAROUSEL",
    icon: "",
    component: CrearCarousel,
    layout: "/admin"
  },
  {
    path: "/editarcarousel",
    name: "EDITAR CAROUSEL",
    icon: "",
    component: EditarCarousel,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - LIBROS
  {
    path: "/consultarlibros",
    name: "LIBROS",
    icon: "",
    component: ConsultarLibros,
    layout: "/admin"
  },
  {
    path: "/crearlibros",
    name: "NUEVO LIBRO",
    icon: "",
    component: CrearLibros,
    layout: "/admin"
  },
  {
    path: "/editarlibros",
    name: "EDITAR LIBRO",
    icon: "",
    component: EditarLibros,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - REVISTAS
  {
    path: "/consultarrevistas",
    name: "REVISTAS",
    icon: "",
    component: ConsultarRevistas,
    layout: "/admin"
  },
  {
    path: "/crearrevistas",
    name: "NUEVA REVISTA",
    icon: "",
    component: CrearRevistas,
    layout: "/admin"
  },
  {
    path: "/editarrevistas",
    name: "EDITAR REVISTA",
    icon: "",
    component: EditarRevistas,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - CUADERNOS DE JURISPRUDENCIA
  {
    path: "/consultarcuadernos",
    name: "CUADERNOS DE JURISPRUDENCIA",
    icon: "",
    component: ConsultarCuadernos,
    layout: "/admin"
  },
  {
    path: "/crearcuadernos",
    name: "NUEVO CUADERNO DE JURISPRUDENCIA",
    icon: "",
    component: CrearCuadernos,
    layout: "/admin"
  },
  {
    path: "/editarcuadernos",
    name: "EDITAR CUADERNO DE JURISPRUDENCIA",
    icon: "",
    component: EditarCuadernos,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES -  CONFERENCIAS
  {
    path: "/consultarConferencia",
    name: "CONFERENCIAS",
    icon: "",
    component: ConsultarConferencia,
    layout: "/admin"
  },
  {
    path: "/crearConferencia",
    name: "NUEVA PUBLICACIÓN DE CONFERENCIA",
    icon: "",
    component: CrearConferencia,
    layout: "/admin"
  },
  {
    path: "/editarConferencia",
    name: "EDITAR PUBLICACIÓN DE CONFERENCIA",
    icon: "",
    component: EditarConferencia,
    layout: "/admin"
  },

  //CEC -PUBLICACIONES - CONVERSATORIO
  {
    path: "/consultarConversatorio",
    name: "CONVERSATORIO",
    icon: "",
    component: ConsultarConversatorio,
    layout: "/admin"
  },
  {
    path: "/crearConversatorio",
    name: "NUEVO CONVERSATORIO",
    icon: "",
    component: CrearConversatorio,
    layout: "/admin"
  },
  {
    path: "/editarConversatorio",
    name: "EDITAR CONVERSATORIO",
    icon: "",
    component: EditarConversatorio,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - DEBATES
  {
    path: "/consultarDebate",
    name: "DEBATES CONSTITUCIONALES",
    icon: "",
    component: ConsultarDebate,
    layout: "/admin"
  },
  {
    path: "/crearDebate",
    name: "NUEVA PUBLICACIÓN DE DEBATES CONSTITUCIONALES",
    icon: "",
    component: CrearDebate,
    layout: "/admin"
  },
  {
    path: "/editarDebate",
    name: "EDITAR PUBLICACIÓN DE DEBATES CONSTITUCIONALES",
    icon: "",
    component: EditarDebate,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - AGENDA CONSTITUCIONAL
  {
    path: "/consultarAgendaConstitucional",
    name: "AGENDA CONSTITUCIONAL",
    icon: "",
    component: ConsultarAgendaConstitucional,
    layout: "/admin"
  },
  {
    path: "/crearAgendaConstitucional",
    name: "NUEVA AGENDA CONSTITUCIONAL",
    icon: "",
    component: CrearAgendaConstitucional,
    layout: "/admin"
  },
  {
    path: "/editarAgendaConstitucional",
    name: "EDITAR AGENDA CONSTITUCIONAL",
    icon: "",
    component: EditarAgendaConstitucional,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - AGENDA CEC
  {
    path: "/consultarAgendaCEC",
    name: "AGENDA CEC",
    icon: "",
    component: ConsultarAgendaCEC,
    layout: "/admin"
  },
  {
    path: "/crearAgendaCEC",
    name: "NUEVA AGENDA CEC",
    icon: "",
    component: CrearAgendaCEC,
    layout: "/admin"
  },
  {
    path: "/editarAgendaCEC",
    name: "EDITAR AGENDA CEC",
    icon: "",
    component: EditarAgendaCEC,
    layout: "/admin"
  },
  //CEC -PUBLICACIONES - RIMAYKUNA CEC
  {
    path: "/consultarRimaykunaCEC",
    name: "RIMAYKUNA CEC",
    icon: "",
    component: ConsultarRimaykunaCEC,
    layout: "/admin"
  },
  {
    path: "/crearRimaykunaCEC",
    name: "NUEVA PUBLICACIÓN - RIMAYKUNA CEC",
    icon: "",
    component: CrearRimaykunaCEC,
    layout: "/admin"
  },
  {
    path: "/editarRimaykunaCEC",
    name: "EDITAR PUBLICACIÓN - RIMAYKUNA CEC",
    icon: "",
    component: EditarRimaykunaCEC,
    layout: "/admin"
  },
  //CEC - FOROS
  {
    path: "/foros",
    name: "FOROS",
    icon: "",
    component: Foros,
    layout: "/admin"
  },
  //CEC - FORO ABIERTO
  {
    path: "/consultarForoAbierto",
    name: "FORO ABIERTO",
    icon: "",
    component: ConsultarForoAbierto,
    layout: "/admin"
  },
  {
    path: "/crearForoAbierto",
    name: "NUEVA PUBLICACIÓN DE FORO ABIERTO",
    icon: "",
    component: CrearForoAbierto,
    layout: "/admin"
  },
  {
    path: "/editarForoAbierto",
    name: "EDITAR PUBLICACIÓN DE FORO ABIERTO",
    icon: "",
    component: EditarForoAbierto,
    layout: "/admin"
  }
  ,
  //CEC -FORO CONSTITUCIONAL
  {
    path: "/consultarForoConstitucional",
    name: "FORO CONSTITUCIONAL",
    icon: "",
    component: ConsultarForoConstitucional,
    layout: "/admin"
  },
  {
    path: "/crearForoConstitucional",
    name: "NUEVA PUBLICACIÓN DE FORO CONSTITUCIONAL",
    icon: "",
    component: CrearForoConstitucional,
    layout: "/admin"
  },
  {
    path: "/editarForoConstitucional",
    name: "EDITAR PUBLICACIÓN DE FORO CONSTITUCIONAL",
    icon: "",
    component: EditarForoConstitucional,
    layout: "/admin"
  },
  //CEC - FORO CONSTI-INCLUYENTE
  {
    path: "/consultarForoConstiIncluyente",
    name: "FORO CONSTI-INCLUYENTE",
    icon: "",
    component: ConsultarForoConstiIncluyente,
    layout: "/admin"
  },
  {
    path: "/crearForoConstiIncluyente",
    name: "NUEVA PUBLICACIÓN DE FORO CONSTI-INCLUYENTE",
    icon: "",
    component: CrearForoConstiIncluyente,
    layout: "/admin"
  },
  {
    path: "/editarForoConstiIncluyente",
    name: "EDITAR PUBLICACIÓN DE FORO CONSTI-INCLUYENTE",
    icon: "",
    component: EditarForoConstiIncluyente,
    layout: "/admin"
  },
  //CEC - UTILITARIOS
  {
    path: "/utilitarioscec",
    name: "UTILITARIOS",
    icon: "",
    component: Utilitarios,
    layout: "/admin"
  },
  //CEC - CURSOS
  {
    path: "/cursos",
    name: "CURSOS PUBLICADOS",
    icon: "",
    component: ConsultarCursos,
    layout: "/admin"
  },
  {
    path: "/crearCursos",
    name: "NUEVO CURSO",
    icon: "",
    component: CrearCursos,
    layout: "/admin"
  },
  {
    path: "/editarCursos",
    name: "EDITAR CURSO",
    icon: "",
    component: EditarCursos,
    layout: "/admin"
  },
  //CEC - VIDEOS DE LOS CURSOS
  {
    path: "/consultarVideos",
    name: "VIDEOS PUBLICADOS",
    icon: "",
    component: ConsultarVideos,
    layout: "/admin"
  },
  {
    path: "/crearVideos",
    name: "NUEVO VIDEO",
    icon: "",
    component: CrearVideos,
    layout: "/admin"
  },
  {
    path: "/editarVideos",
    name: "EDITAR VIDEO",
    icon: "",
    component: EditarVideos,
    layout: "/admin"
  },
  //JURISDICCIONAL...
  //Jurisdiccional - Noticias Salas
  {
    path: "/salasnoticias",
    name: "CONSULTAR NOTICIAS SALAS",
    icon: "",
    component: ConsultarSalasNoticias,
    layout: "/admin"
  },
  {
    path: "/crearsalasnoticias",
    name: "CREAR NOTICIA SALA",
    icon: "",
    component: CrearSalasNoticias,
    layout: "/admin"
  },
  {
    path: "/editarsalasnoticias",
    name: "EDITAR NOTICIA SALA",
    icon: "",
    component: EditarSalasNoticias,
    layout: "/admin"
  },
  //Jurisdiccional - Noticias del CEC
  {
    path: "/cecnoticias",
    name: "CONSULTAR NOTICIAS DEL CEC",
    icon: "",
    component: ConsultarCECNoticias,
    layout: "/admin"
  },
  {
    path: "/crearcecnoticias",
    name: "CREAR NOTICIA DEL CEC",
    icon: "",
    component: CrearCECNoticias,
    layout: "/admin"
  },
  {
    path: "/editarcecnoticias",
    name: "EDITAR NOTICIA DEL CEC",
    icon: "",
    component: EditarCECNoticias,
    layout: "/admin"
  },
  //Jurisdiccional - Expediente votados - antes agendas pleno
    {
    path: "/votados",
    name: "CONSULTAR EXPEDIENTES VOTADOS",
    icon: "",
    component: ConsultarExpedientesVotados,
    layout: "/admin"
  },
  {
    path: "/crearExpedientesVotados",
    name: "CREAR EXPEDIENTES VOTADOS",
    icon: "",
    component: CrearExpedientesVotados,
    layout: "/admin"
  },
  {
    path: "/editarExpedientesVotados",
    name: "EDITAR EXPEDIENTES VOTADOS",
    icon: "",
    component: EditarExpedientesVotados,
    layout: "/admin"
  },
  //Jurisdiccional - Supervision de sentencias (CAUSA SEGUIMIENTO)
  {
    path: "/supervision",
    name: "CONSULTAR CAUSAS",
    icon: "",
    component: ConsultarCausaSeguimiento,
    layout: "/admin"
  },
  //Jurisdiccional - Supervision de sentencias (SEGUIMIENTO)
  {
    path: "/consultarseguimiento",
    name: "CONSULTAR SEGUIMIENTOS",
    icon: "",
    component: ConsultarSeguimiento,
    layout: "/admin"
  },
  {
    path: "/crearseguimiento",
    name: "CREAR SEGUIMIENTO",
    icon: "",
    component: CrearSeguimiento,
    layout: "/admin"
  }, 
  {
    path: "/editarseguimiento",
    name: "EDITAR SEGUIMIENTO",
    icon: "",
    component: EditarSeguimiento,
    layout: "/admin"
  }, 
  //Jurisdiccional - Ultimas Audiencias
  {
    path: "/ultimasaudiencias",
    name: "CONSULTAR ULTIMAS AUDIENCIAS",
    icon : "",
    component: ConsultarUltimasAudiencias,
    layout: "/admin"
  },
  {
    path: "/crearultimasaudiencias",
    name: "CREAR ULTIMA AUDIENDIA",
    icon: "",
    component: CrearUltimasAudiencias,
    layout: "/admin"
  },
  {
    path: "/editarultimasaudiencias",
    name: "EDITAR ULTIMA AUDIENDIA",
    icon: "",
    component: EditarUltimasAudiencias,
    layout: "/admin"
  },
  //Jurisdiccional - Votaciones
  {
    path: "/votaciones",
    name: "CONSULTAR VOTACIONES",
    icon: "",
    component: Votaciones,
    layout: "/admin"
  },
  {
    path: "/eventos",
    name: "CONSULTAR EVENTOS",
    icon: "",
    component: Evento,
    layout: "/admin"
  },
  {
    path: "/personal",
    name: "CONSULTAR PERSONAL",
    icon: "",
    component: Personal,
    layout: "/admin"
  },
  {
    path: "/opciones",
    name: "CONSULTAR OPCIONES",
    icon: "",
    component: Opciones,
    layout: "/admin"
  },
  {
    path: "/listadoMiembros",
    name: "CONSULTAR MIEMBROS",
    icon: "",
    component: Miembros,
    layout: "/admin"
  },
  {
    path: "/listadoVotantes",
    name: "LISTA DE VOTANTES",
    icon: "",
    component: Votantes,
    layout: "/admin"
  },
  //Jurisdiccional - Penalidades
  {
    path: "/penalidades",
    name: "CONSULTAR PENALIDADES",
    icon: "",
    component: ConsultarPenalidades,
    layout: "/admin"
  },
  {
    path: "/crearPenalidades",
    name: "NUEVO REGISTRO",
    icon: "",
    component: CrearPenalidades,
    layout: "/admin"
  },
  {
    path: "/editarPenalidades",
    name: "EDITAR REGISTRO",
    icon: "",
    component: EditarPenalidades,
    layout: "/admin"
  },
  //Portal Web
  {
    path: "/utilitariosPortal",
    name: "CAROUSEL PORTAL",
    icon: "",
    component: CarruselPortal,
    layout: "/admin"
  },
  {
    path: "/consultarCarouselPortal",
    name: "CONSULTAR CAROUSEL PORTAL",
    icon: "",
    component: ConsultarCarruselPortal,
    layout: "/admin"
  },
  {
    path: "/crearCarouselPortal",
    name: "NUEVO CAROUSEL",
    icon: "",
    component: CrearCarruselPortal,
    layout: "/admin"
  },
  {
    path: "/editarCarouselPortal",
    name: "EDITAR CAROUSEL",
    icon: "",
    component: EditarCarruselPortal,
    layout: "/admin"
  },
  // RRHH - PAPELETAS
  {
    path: "/administrarPapeletas",
    name: "ADMINISTRAR PAPELETAS ",
    icon: "",
    component: ConsultarPapeletasAdm,
    layout: "/admin"
  },
  {
    path: "/crearPapeletaAdm",
    name: "NUEVA PAPELETA",
    icon: "",
    component: CrearPapeletaAdm,
    layout: "/admin"
  },
  {
    path: "/verPapeletaAdm",
    name: "DETALLE DE PAPELETA",
    icon: "",
    component: VerPapeletaAdm,
    layout: "/admin"
  },
  // RRHH - BOLETAS
  {
    path: "/administrarBoletas",
    name: "BOLETAS",
    icon: "",
    component: ConsultarBoletasAdm,
    layout: "/admin"
  }, 
  // RRHH - TRABAJADORES
  {
    path: "/administrarTrabajador",
    name: "ADMINISTRAR TRABAJADORES",
    icon: "",
    component: ConsultarTrabajadores,
    layout: "/admin"
  }, 
  {
    path: "/crearTrabajador",
    name: "NUEVO TRABAJADOR",
    icon: "",
    component: CrearTrabajadores,
    layout: "/admin"
  },
   {
    path: "/editarTrabajadoresAdm",
    name: "EDITAR TRABAJADOR",
    icon: "",
    component: EditarTrabajadores,
    layout: "/admin"
  },
  {
    path:"/verPapeletaTrabajador",
    name:"PAPELETAS POR TRABAJADOR",
    icon:"",
    component: VerPapeletaTrabajador,
    layout:"/admin"
  }, 
  //TESORERIA -PENALIDADES
  {
    path: "/adminPenalidades",
    name: "PENALIDADES",
    icon:"",
    component: Penalidades,
    layout:"/admin"
  },
  //RRHH - AREAS Y PERMISOS 
  {
    path:"/administrarAreas",
    name:"ADMINISTRAR ÁREAS Y PERMISOS",
    icon:"",
    component: General,
    layout:"/admin"
  },
  //RRHH - Administration - Cargos
  {
    path:"/administrarCargos",
    name:" ADMINISTRAR CARGOS",
    icon:"",
    component: Cargos,
    layout:"/admin"
  },
  {
    path:"/administrarRegimen",
    name:"ADMINISTRAR RÉGIMEN",
    icon:"",
    component: Regimen,
    layout:"/admin"
  },
  {
    path:"/administrarUtilitarios",
    name:"ADMINISTRAR UTILITARIOS",
    icon:"",
    component: UtilitariosAdm,
    layout:"/admin"
  },
  {
    path:"/administrarBanco",
    name:"ADMINISTRAR BANCOS",
    icon:"",
    component: Bancos,
    layout:"/admin"
  },
  {
    path:"/administrarPension",
    name:"ADMINISTRAR PENSIONES",
    icon:"",
    component: Pension,
    layout:"/admin"
  },
  {
    path:"/administrarAFP",
    name:"ADMINISTRAR FONDOS DE PENSIONES",
    icon:"",
    component: AFP,
    layout:"/admin"
  },
  {
    path:"/administrarTipoPapeleta",
    name:" ADMINISTRAR TIPOS DE PAPELETAS",
    icon:"",
    component: TipoPapeleta,
    layout:"/admin"
  },
  // Jurisprudencia - Tesauros
  {
    path: "/tesauros",
    name: "LISTA DE EXPEDIENTES DE TESAURO",
    icon: "",
    component: ConsultarTesauros,
    layout: "/admin"
  }
  , // RRHH - AREAS ADM
  {
    path: "/adminAreas",
    name: "LISTA DE ÁREAS DE TC",
    icon: "",
    component: AreasAdm,
    layout: "/admin"
  }, 
  {
    path: "/crearArea",
    name: "NUEVA ÁREA DEL TC",
    icon: "",
    component: CrearArea,
    layout: "/admin"
  }, 
  {
    path: "/editarArea",
    name: "EDITAR ÁREA DEL TC",
    icon: "",
    component: EditarArea,
    layout: "/admin"
  }, 
  {
    path: "/verPermisos",
    name: "PERMISOS",
    icon: "",
    component: Permisos,
    layout: "/admin"
  }, 
  {
    path: "/verSubareas",
    name: "SUBÁREAS",
    icon: "",
    component: Subareas,
    layout: "/admin"
  }, 
  {
    path: "/verTrabajadores",
    name: "TRABAJADORES",
    icon: "",
    component: TrabajadoresAreas,
    layout: "/admin"
  },
  {
    path:"/administrarTipoDescansoMedico",
    name:"TIPO DE DESCANSO MEDICO",
    icon:"",
    component: TipoDescansoMedico,
    layout:"/admin"
  },
  {
    path:"/administrarConceptosPago",
    name:"CONCEPTOS DE PAGO",
    icon:"",
    component: ConceptosPago,
    layout:"/admin"
  },
  //PERSONAL - MANUALES
  {
    path:"/manuales",
    name: "DIRECTORIOS",
    icon:"",
    component: Directorios,
    layout :"/admin"
  },
  {
    path:"/manual",
    name: "MANUALES",
    icon:"",
    component: ListManuales,
    layout :"/admin"
  },
  //INTEGRIDAD
  {
    path:"/modeloIntegridad",
    name: "MODELO INTEGRIDAD",
    icon:"",
    component: ModeloIntegridad,
    layout :"/admin"
  },
  //BOLETINES
  {
    path: "/boletines",
    name: "BOLETINES",
    icon: "",
    component: Boletines,
    layout: "/admin"
  },
  {
    path: "/crearBoletin",
    name: "CREAR NUEVO BOLETIN",
    icon: "",
    component: CrearBoletin,
    layout: "/admin"
  },
  {
    path: "/editarBoletin",
    name: "EDITAR BOLETIN",
    icon: "",
    component: EditarBoletin,
    layout: "/admin"
  },
  {
    path: "/listarEnviado",
    name: "LISTAR BOLETIN ENVIADOS",
    icon: "",
    component: ListarEnviado,
    layout: "/admin"
  },
  {
    path: "/inscripciones",
    name: "LISTAR INSCRIPCIONES",
    icon: "",
    component: Inscripciones,
    layout: "/admin"
  },
  {
    path: "/plantillas",
    name: "LISTAR PLANTILLAS",
    icon: "",
    component: Plantillas,
    layout: "/admin"
  },
  {
    path: "/certificados",
    name: "LISTAR CERTIFICADOS",
    icon: "",
    component: Certificados,
    layout: "/admin"
  },
  //DOCUMENTARIO
  {
    path: "/utilitariosStd",
    name: "DOCUMENTARIO",
    icon: "",
    component: MenuDocumentario,
    layout: "/admin"
  },
  {
    path: "/contadorTramite",
    name: "CONTADOR DE TRÁMITES",
    icon: "",
    component: ContadorTramite,
    layout: "/admin"
  },
  {
    path: "/listadoBandejas",
    name: "LISTADO DE BANDEJAS",
    icon: "",
    component: ListadoBandejas,
    layout: "/admin"
  },
  {
    path: "/secuenciaArea",
    name: "SECUENCIA POR ÁREA",
    icon: "",
    component: SecuenciaxArea,
    layout: "/admin"
  },
  {
    path: "/secuenciaPeriodo",
    name: "SECUENCIA POR PERIODO",
    icon: "",
    component: SecuenciaxPeriodo,
    layout: "/admin"
  },
  // JEFATURA
  {
    path: "/tramites",
    name: "REPORTE DE TRÁMITES",
    icon: "",
    component: ReporteTramites,
    layout: "/admin"
  },
  // BOLETAS PENSIONISTAS
  {
    path: "/pensionistas",
    name: "BOLETAS DE PENSIONISTAS",
    icon: "",
    component: Pensionistas,
    layout: "/admin"
  },
  //Certificados y Directorios
  {
    path: "/directoriocertificado",
    name: "CERTIFICADOS",
    icon: "",
    component: DirectorioCertificado,
    layout: "/admin"
  },
  //Descanso Médico
  {
    path: "/administrarDesMedicos",
    name: "ADMINISTRAR DESCANSOS MÉDICOS",
    icon: "",
    component: DescansoMedico,
    layout: "/admin"
  }
  
  ,//descanso medico
  
  {
    path: "/diasDescansoMedico",
    name: "DIAS DE DESCANSO MÉDICO",
    icon: "",
    component: DiasDescansoMedico,
    layout: "/admin"
  }
  ,//descanso medico
  
  {
    path: "/crearDescansoMedico",
    name: "NUEVO DESCANSO MÉDICO",
    icon: "",
    component: NuevoDescansoMedico,
    layout: "/admin"
  }
  
  ,//descanso medico x trabajador
  
  {
    path: "/verDescansoTrabajador",
    name: "DESCANSOS MEDICOS POR TRABAJADOR",
    icon: "",
    component: VerDescansoTrabajador,
    layout: "/admin"
  }
  ,//descanso medico
  {
    path: "/editarDescansoMedico",
    name: "EDITAR DESCANSO MÉDICO",
    icon: "",
    component: EditarDescansoMedico,
    layout: "/admin"
  },
  {
   path: "/gestionarDM",
   name : "GESTIONAR DESCANSOS MÉDICOS",
   icon: "",
   component: GestionarDM,
   layout:"/admin"
  },
  {
    path :"/reporteDias",
    name :"REPORTE DE DESCANSOS MÉDICOS",
    icon:"",
    component : ReporteDias,
    layout:"/admin"
  },
  {
    path:"/administrarVacaciones",
    name: "ADMINISTRAR VACACIONES",
    icon:"",
    component : Vacaciones,
    layout:"/admin"
  },
  {
    path:"/vacacionesDetalle",
    name :"DETALLE DE VACACIONES",
    icon:"",
    component : DetalleVacaciones,
    layout:"/admin"
  },
  {
    path:"/verVacacionesTrabajador",
    name :"VACACIONES POR TRABAJADOR",
    icon:"",
    component : VerVacacionesTrabajador,
    layout:"/admin"
  },
  {
    path:"/reporteVacaciones",
    name :"REPORTE DE VACACIONES PENDIENTES",
    icon:"",
    component : ReporteVacaciones,
    layout:"/admin"
  },
  {
    path :"/vacacionesTrabajador",
    name: "VACACIONES DEL TRABAJADOR",
    icon:"",
    component : AllVacacionesTrabajador,
    layout:"/admin"
  },
  {
    path:"/certiFirmados",
    name:"CERTIFICADOS FIRMADOS",
    icon:"",
    component: CertiFirmados,
    layout:"/admin"
  },
  {
    path:"/tipoCese",
    name:"ADMINISTRAR TIPO DE CESE",
    icon:"",
    component: TipoCese,
    layout:"/admin"
  },
  {
    path :"/generarFirma",
    name:"GENERADOR DE FIRMA",
    icon:"",
    component: GenerarFirma,
    layout:"/admin"
  },
   //CEC - PUBLICACIONES - COLOQUIOS
   {
    path: "/coloquios",
    name: "COLOQUIOS CONSTITUCIONALES",
    icon: "",
    component: ConsultarColoquios,
    layout: "/admin"
  },
  {
    path: "/crearColoquios",
    name: "NUEVA PUBLICACIÓN - COLOQUIOS",
    icon: "",
    component: CrearColoquios,
    layout: "/admin"
  },
  {
    path: "/editarColoquios",
    name: "EDITAR PUBLICACIÓN - COLOQUIOS",
    icon: "",
    component: EditarColoquios,
    layout: "/admin"
  },
   //CEC - PUBLICACIONES - REPLICA CONSTITUCIONAL
  {
    path: "/replica",
    name: "RÉPLICA CONSTITUCIONAL",
    icon: "",
    component: ConsultarReplica,
    layout: "/admin"
  },
  {
    path: "/crearReplica",
    name: "NUEVA PUBLICACIÓN - RÉPLICA",
    icon: "",
    component: CrearReplica,
    layout: "/admin"
  },
  {
    path: "/editarReplica",
    name: "EDITAR PUBLICACIÓN - RÉPLICA",
    icon: "",
    component: EditarReplica,
    layout: "/admin"
  },
  {
    path :"/listaTramite",
    name:"TRÁMITE DOCUMENTARIO",
    icon:"",
    component: ListaTramite,
    layout:"/admin"
  },
  {
    path :"/aplicaciones",
    name:"LISTA DE TRABAJADORES",
    icon:"",
    component: AreaTrabajadores,
    layout:"/admin"
  }
  //Resoluciones
  ,
  {
    path :"/resoluciones",
    name:"RESOLUCIONES",
    icon:"",
    component: Resoluciones,
    layout:"/admin"
  },
  {
    path :"/nuevaResolucion",
    name:"NUEVA RESOLUCIÓN",
    icon:"",
    component: NuevaResolucion,
    layout:"/admin"
  },
  {
    path :"/editarResolucion",
    name:"EDITAR RESOLUCIÓN",
    icon:"",
    component: EditarResolucion,
    layout:"/admin"
  },
  {
    path :"/notificar",
    name:"NOTIFICAR RESOLUCIONES",
    icon:"",
    component: Notificar,
    layout:"/admin"
  },
  {
    path :"/categorias",
    name:"CATEGORÍAS",
    icon:"",
    component: Categorias,
    layout:"/admin"
  },
  {
    path :"/misResoluciones",
    name:"RESOLUCIONES",
    icon:"",
    component: MisResoluciones,
    layout:"/admin"
  }
  ,
  {
    path :"/verNotificados",
    name:"NOTIFICADOS",
    icon:"",
    component: VerNotificados,
    layout:"/admin"
  },
  {
    path: "/visorResoluciones",
    name: "VISOR DE RESOLUCIONES",
    icon: "",
    component: VisorResolucion,
    layout: "/admin"
  }
  ,
  {
    path: "/produccion",
    name: "ADMINISTRADOR",
    icon: "",
    component: Produccion,
    layout: "/admin"
  }
  ,
  {
    path: "/verReporte",
    name: "REPORTE",
    icon: "",
    component: VerReporte,
    layout: "/admin"
  }
  ,
  {
    path: "/espacioInternacional",
    name: "ADMINISTRADOR DE ESPACIO INTERNACIONAL",
    icon: "",
    component: EspacioInternacional,
    layout: "/admin"
  },
  {
    path: "/adminEspacio",
    name: "MÓDULO DE ESPACIO INTERNACIONAL",
    icon: "",
    component: AdminEspacio,
    layout: "/admin"
  },
  {
    path: "/jefatura",
    name: "ASIGNACIÓN DEL PERSONAL A CARGO",
    icon: "",
    component: AsignacionJefes,
    layout: "/admin"
  }
  ,
  {
    path: "/secciones",
    name: "SECCIONES",
    icon: "",
    component: Secciones,
    layout: "/admin"
  },
  {
    path: "/adminSecciones",
    name: "ADMINISTRAR SECCIONES",
    icon: "",
    component: AdminSecciones,
    layout: "/admin"
  }
  ,
  {
    path: "/cecProgramas",
    name: "PROGRAMAS",
    icon: "",
    component: ProgramasCEC,
    layout: "/admin"
  },
  {
    path: "/equipoCEC",
    name: "EQUIPO CEC",
    icon: "",
    component: Equipo,
    layout: "/admin"
  }
  ,
  {
    path: "/formacionCapacitacion",
    name: "FORMACIÓN Y CAPACITACIÓN",
    icon: "",
    component: FormacionCap,
    layout: "/admin"
  },
  //dialogo con magistrados
  {
    path: "/consultarDialogo",
    name: "DIÁLOGOS CON MAGISTRADOS",
    icon: "",
    component: ConsultarDialogo,
    layout: "/admin"
  },
  {
    path: "/crearDialogo",
    name: "NUEVA PUBLICACIÓN DE DIÁLOGO CON MAGISTRADOS",
    icon: "",
    component: CrearDialogo,
    layout: "/admin"
  },
  {
    path: "/editarDialogo",
    name: "EDITAR PUBLICACIÓN DE DIÁLOGO CON MAGISTRADOS",
    icon: "",
    component: EditarDialogo,
    layout: "/admin"
  },
  {
    path: "/memorias",
    name: "MEMORIAS",
    icon: "",
    component: Memorias,
    layout: "/admin"
  },
  {
    path: "/memoriasAdmin",
    name: "ADMINISTRADOR MEMORIAS",
    icon: "",
    component: MemoriaAdmin,
    layout: "/admin"
   } ,
    {
      path: "/crearMemoria",
      name: "NUEVA MEMORIA",
      icon: "",
      component: CrearMemoria,
      layout: "/admin"
    },
    {
      path: "/editarMemoria",
      name: "EDITAR MEMORIA",
      icon: "",
      component: EditarMemoria,
      layout: "/admin"
    },
    {
      path: "/lineasInvestigacion",
      name: "LÍNEAS DE INVESTIGACIÓN",
      icon: "",
      component: Listadelineas,
      layout: "/admin"
    },
    {
      path: "/adminLineas",
      name: "ADMINISTRAR CONTENIDO",
      icon: "",
      component: AdminLineas,
      layout: "/admin"
    },
    {
      path: "/listasecciones",
      name: "CONTENIDOS",
      icon: "",
      component: ListaSecciones,
      layout: "/admin"
    },
    {
      path: "/listasubsecciones",
      name: "SUB-CONTENIDOS",
      icon: "",
      component: ListaSubSecciones,
      layout: "/admin"
    },
    {
      path: "/consultarActividades",
      name: "ACTIVIDADES DE CAPACITACIÓN",
      icon: "",
      component: ActCapacitacion,
      layout: "/admin"
    },
    {
      path: "/centroDocumentacion",
      name: "CENTRO DE DOCUMENTACIÓN",
      icon: "",
      component: CentroDocumentacion,
      layout: "/admin"
    },
    {
      path: "/ferias",
      name: "FERIAS",
      icon: "",
      component: Ferias,
      layout: "/admin"
    },
    {
      path: "/crearFeria",
      name: "NUEVA FERIA",
      icon: "",
      component: CrearFeria,
      layout: "/admin"
    },
    {
      path: "/editarFeria",
      name: "EDITAR FERIA",
      icon: "",
      component: EditarFeria,
      layout: "/admin"
    },
    {
      path: "/feriaDetalle",
      name: "DETALLE FERIA",
      icon: "",
      component: DetalleFeria,
      layout: "/admin"
    },
    {
      path: "/verResoluciones",
      name: "LISTA DE RESOLUCIONES",
      icon: "",
      component: VerConcursos,
      layout: "/admin"
    }
    ,
    {
      path: "/administrarMarcaciones",
      name: "ADMINISTRAR MARCACIONES",
      icon: "",
      component: AdminMarcaciones,
      layout: "/admin"
    }
    ,
    {
      path: "/legajo",
      name: "LEGAJOS PERSONAL",
      icon: "",
      component: ListadoTrabajadores,
      layout: "/admin"
    }
    ,
    {
      path: "/miLegajo",
      name: "MI LEGAJO",
      icon: "",
      component: ConsultarMiLegajo,
      layout: "/admin"
    }
    ,
    {
      path: "/legajoTrabajador",
      name: "LEGAJO TRABAJADOR",
      icon: "",
      component: ConsultarLegajoTrabajador,
      layout: "/admin"
    }      
    ,
    {
      path: "/seccionesLegajo",
      name: "CATEGORÍAS",
      icon: "",
      component: SeccionesLegajo,
      layout: "/admin"
    }
    ,
    {
      path: "/fichaPersonal",
      name: "MI LEGAJO",
      icon: "",
      component: FichaPersonal,
      layout: "/admin"
    }
    ,
    {
      path: "/datosGenerales",
      name: "MI LEGAJO",
      icon: "",
      component: DatosGenerales,
      layout: "/admin"
    }
    ,
    {
      path: "/formacionAcademica",
      name: "MI LEGAJO",
      icon: "",
      component: ListadoFormacion,
      layout: "/admin"
    }
    ,
    {
      path: "/listadoFamiliar",
      name: "MI LEGAJO",
      icon: "",
      component: ListadoFamiliar,
      layout: "/admin"
    }
    ,
    {
      path: "/agregarFamiliar",
      name: "MI LEGAJO",
      icon: "",
      component: AgregarFamiliar,
      layout: "/admin"
    }
    ,
    {
      path: "/agregarFormacion",
      name: "MI LEGAJO",
      icon: "",
      component: AgregarFormacion,
      layout: "/admin"
    }
    ,
    {
      path: "/experienciaLaboral",
      name: "MI LEGAJO",
      icon: "",
      component: ListadoExperiencia,
      layout: "/admin"
    }
    ,
    {
      path: "/agregarExperiencia",
      name: "MI LEGAJO",
      icon: "",
      component: AgregarExperiencia,
      layout: "/admin"
    }
    ,
    {
      path: "/declaracionJurada",
      name: "MI LEGAJO",
      icon: "",
      component: DeclaracionJurada,
      layout: "/admin"
    }
    ,
    {
      path: "/editarFamiliar",
      name: "MI LEGAJO",
      icon: "",
      component: EditarFamiliar,
      layout: "/admin"
    }
    ,
    {
      path: "/editarFormacion",
      name: "MI LEGAJO",
      icon: "",
      component: EditarFormacion,
      layout: "/admin"
    }
    ,
    {
      path: "/editarExperiencia",
      name: "MI LEGAJO",
      icon: "",
      component: EditarExperiencia,
      layout: "/admin"
    }
    ,
    {
      path: "/generarCaratula",
      name: "MI LEGAJO",
      icon: "",
      component: GenerarCaratula,
      layout: "/admin"
    }
    ,
    {
      path: "/reporteDesMedico",
      name: "REPORTE DE DESCANSO MÉDICO",
      icon: "",
      component: ReporteDM,
      layout: "/admin"
    }
    ,
    {
      path: "/descansosMedicos",
      name: "DESCANSOS MÉDICOS",
      icon: "",
      component: ListadoDescansoMedico,
      layout: "/admin"
    }
    ,
    {
      path: "/agregarDescansoMedico",
      name: "NUEVO DESCANSO MÉDICO",
      icon: "",
      component: AgregarDescansoMedico,
      layout: "/admin"
    }
    ,
    {
      path: "/modificarDescansoMedico",
      name: "EDITAR DESCANSO MÉDICO",
      icon: "",
      component: ModificarDescansoMedico,
      layout: "/admin"
    }
    ,
    {
      path: "/misVacaciones",
      name: "MIS VACACIONES",
      icon: "",
      component: ListadoVacaciones,
      layout: "/admin"
    }
    ,
    {
      path: "/reporteGeneralVacaciones",
      name: "REPORTE VACACIONES",
      icon: "",
      component: ReporteGeneralV,
      layout: "/admin"
    }
    ,
    {
      path: "/consultarVacaciones",
      name: "DETALLE DE VACACIONES",
      icon: "",
      component: ConsultarVacaciones,
      layout: "/admin"
    }        
    ,
    {
      path: "/autorizarDescanso",
      name: "AUTORIZAR DESCANSO MÉDICO",
      icon: "",
      component: AutorizarDescansoM,
      layout: "/admin"
    }
    ,
    {
      path: "/reporteControl",
      name: "REPORTE CONTROL DE DESCANSO MÉDICO",
      icon: "",
      component: ReporteControl,
      layout: "/admin"
    }
    ,
    {
      path: "/reporteRegistro",
      name: "REPORTE DE DESCANSO MÉDICO",
      icon: "",
      component: ReporteRegistro,
      layout: "/admin"
    }
    ,
    {
      path: "/reporteAcumulado",
      name: "REPORTE ACUMULADO DE DESCANSO MÉDICO",
      icon: "",
      component: ReporteAcumulado,
      layout: "/admin"
    },
    {
      path: "/administrarDesplazamientos",
      name: "Desplazamiento",
      icon: "",
      component: Desplazamiento,
      layout: "/admin"
    },
    {
      path: "/NuevoDesplazamiento",
      name: "Nuevo Desplazamiento",
      icon: "",
      component: NuevoDesplazamiento,
      layout: "/admin"
    },
    {
      path: "/EditarDesplazamiento",
      name: "Editar Desplazamiento",
      icon: "",
      component: DetalleDesplazamiento,
      layout: "/admin"
    },
    {
      path: "/DetallePostulanteInterno",
      name: "Detalle Postulante Interno",
      icon: "",
      component: DetallePostulanteInterno,
      layout: "/admin"
    },
    {
      path: "/normatividad",
      name: "NORMATIVIDAD",
      icon: "",
      component: Normatividad,
      layout: "/admin"
    },
    {
      path: "/crearnormatividad",
      name: "REGISTRAR NORMATIVIDAD",
      icon: "",
      component: CrearNormatividad,
      layout: "/admin"
    },
    {
      path: "/editarnormatividad",
      name: "ACTUALIZAR NORMATIVIDAD",
      icon: "",
      component: EditarNormatividad,
      layout: "/admin"
    },
    {
      path: "/fondoEditorial",
      name: "FONDO EDITORIAL",
      icon: "",
      component: FondoEditorial,
      layout: "/admin"
    },
    {
      path: "/consultarEditorial",
      name: "FONDO EDITORIAL",
      icon: "",
      component: ConsultarEditorial,
      layout: "/admin"
    },
    {
      path: "/creareditorial",
      name: "REGISTRAR EDITORIAL",
      icon: "",
      component: CrearEditorial,
      layout: "/admin"
    },
    {
      path: "/editareditorial",
      name: "ACTUALIZAR EDITORIAL",
      icon: "",
      component: EditarEditorial,
      layout: "/admin"
    },
    {
      path: "/SubCategoriaCuaderno",
      name: "Sub Categoría Cuaderno",
      icon: "",
      component: SubCategoriaCuaderno,
      layout: "/admin"
    },
    {
      path: "/pinacoteca",
      name: "Pinacoteca",
      icon: "",
      component: Pinacoteca,
      layout: "/admin"
    }, 
    {
      path: "/listSubCategorias",
      name: "LISTA DE ACTIVIDADES DE CAPACITACION",
      icon: "",
      component: ListSubCategorias,
      layout: "/admin"
    },
    {
      path: "/listSubSecciones",
      name: "LISTA DE SECCIONES",
      icon: "",
      component: ListSubSecciones,
      layout: "/admin"
    }
    ,
    {
      path: "/opcionJefatura",
      name: "OPCIÓN JEFATURA",
      icon: "",
      component: OpcionJefatura,
      layout: "/admin"
    },
    {
      path: "/consolidado",
      name: "CONSOLIDADO",
      icon: "",
      component: Consolidado,
      layout: "/admin"
    }
];

export default dashboardRoutes;
